<template>
  <div class="welfare_page">
    <img src="@/assets/images/luyangmao/welfare.jpg" alt="">
    <!-- <img src="@/assets/images/luyangmao/welfare1.jpg" alt="">
    <img src="@/assets/images/luyangmao/welfare2.jpg" alt="">
    <img src="@/assets/images/luyangmao/welfare3.jpg" alt="">
    <img src="@/assets/images/luyangmao/welfare4.jpg" alt=""> -->
    <!-- <img src="@/assets/images/luyangmao/welfare5.jpg" alt=""> -->
    <!-- <img src="@/assets/images/luyangmao/form.jpg" class="form" alt=""> -->
    <!-- <div class="form">
      <div class="title">
        <img src="@/assets/images/luyangmao/welfare_title.jpg" alt="">
      </div>
      <div class="welfare_bd">
        <div class="welfare_left">
          <div class="item">1.扫二维码，关注公众号</div>
          <div class="item">2.在公众号输入“领产品”</div>
          <div class="item">3.弹出操作页面，点击填写地址信息即可领取</div>
        </div>
        <div class="welfare_right">

        </div>
      </div>
      <div class="welfare_fd">
        <div class="item">
          *每期福利产品会进行轮换，活动详情可咨询羽素官方公众号
        </div> 
        <div class="item">
          *每个ID仅参加一次，重复登记不发货，不接受退货退款，敬请谅解
        </div> 
        <div class="item">
          *免费领产品活动需要用户支付邮费，方可免费领取羽素人气产品一件
        </div> 
      </div>
    </div> -->
  </div>
</template>

<script>

export default {
  name: 'Welfare',
  components: {
  }
}
</script>
<style scoped lang='scss'>
.welfare_page{
  position: relative;
  .form{
    width: 1260px;
    position: absolute;
    bottom: 60px;
    left: 50%;
    margin-left: -630px;
  }
}
</style>
